import curlyPlane from "../../assets/icons/curlyPlane.svg"






const CurlyPlane = () => {
  return (
 <img src={curlyPlane} alt="" />
  );
};

export default CurlyPlane;
