import React from 'react'
import ProductHeader from '../../../../components/Resuseables/ProductsHeader/ProductHeader';
import airtimeImg from "../../../../assets/images/airtimehead.png"




const AirtimeTop = () => {
  return (
    <div className="w-full flex flex-col items-center  gap-10 py-28 text-white">
    <ProductHeader title={"AIRTIME"} headerImg={airtimeImg} />
   
  </div>
  )
  
}

export default AirtimeTop;