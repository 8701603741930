import React from "react";
import GlovoLogo from "../../../components/svgs/GlovoLogo";
import BranchLogo from "../../../components/svgs/BranchLogo";
import SendChamp from "../../../components/svgs/SendChamp";
import MonLogo from "../../../components/svgs/MonLogo";
import OpayLogo from "../../../components/svgs/OpayLogo";
import PalmpayLogo from "../../../components/svgs/PalmpayLogo";

const Ourpartners = (props) => {
  return (
    <div
      className="ourPartners w-full  flex flex-col md:gap-5 align-center justify-center text-center px-3 md:px-10 py-4 md:pt-8 md:pb-14 "
      style={{ backgroundColor: "#DCE7FE" }}
      {...props}
    >
      <h4 className="text-xl sm:text-2xl" style={{ color: "#878787" }}>
        Our Partners
      </h4>

      <div className="flex justify-center align-center w-full">
        <div className="flex items-center justify-center gap-3 sm:gap-10 w-full ">
          <GlovoLogo  />
          <BranchLogo />
          <SendChamp />
          <MonLogo />
          <OpayLogo />
          <PalmpayLogo />
        </div>
      </div>
    </div>
  );
};

export default Ourpartners;
