import React, { useState } from "react";
import footerBg from "../../../assets/images/footerbg.png";
import expand from "../../../assets/icons/expand.svg";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";
import searchIcon from "../../../assets/icons/Search.svg";
import { questionsAndAnswers, questionsType } from "../../../mock/data";

const Faq = () => {
  const [showQuestionType, setShowQuestionType] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleQuestionType = () => {
    setShowQuestionType(!showQuestionType);
  };

  const toggleAccordion = (index) => {
    setExpandedAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="w-full flex flex-col items-center  gap-10 py-28 text-white">
      <div
        className="w-full h-[281px] flex flex-col items-center  gap-10 px-4 sm:px-10 xl:px-28 py-28 text-white"
        style={{
          backgroundImage: `url(${footerBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <SectionHeading title={"How can we help ?"} color={"#fff"} />

        <div className="w-4/5 flex gap-8  bg-white bg-opacity-40 rounded-md pl-4   ">
          <img src={searchIcon} alt="" className="h-10" />

          <input
            type="text"
            placeholder="search questions"
            className="w-full bg-transparent outline-none text-white placeholder-white"
          />
        </div>
      </div>

      <div className="w-full flex flex-col items-center  gap-10   sm:px-10 xl:px-28 text-white">
        <SectionHeading
          title={"Frequently Asked Questions"}
          color={"#0050ae"}
        />

        <div className="w-full  md:w-4/5 flex flex-col lg:flex-row  px-4 gap-10 lg:gap-20 ">
          <div className="min-w-56">
            <ul
              className="hidden whitespace-nowrap lg:flex flex-col font-bold"
              style={{ color: "#483B6E" }}
            >
              {questionsType.map((item, idx) => (
                <li
                  key={idx}
                  className="hover:text-base text-xs cursor-pointer py-4 px-4 border-b-2 "
                >
                  {item.type}
                </li>
              ))}
            </ul>


            {/* DISPLAY drop down on mobile */}
            <div className="lg:hidden text-black ">
              <div className="display flex gap-4 font-bold pr-4 border-2">
                <input
                  type="text"
                  placeholder="General information"
                  className="w-full px-2 py-2"
                />
                <img
                  src={expand}
                  alt=""
                  className="cursor-pointer"
                  onClick={handleQuestionType}
                />
              </div>
               {showQuestionType && (
                <div className="shadowBg mt-1 rounded-md bg-white">
                  <ul className="flex flex-col text-black ">
                    {questionsType.map((item, idx) => (
                      <li
                        key={idx}
                        className="hover:text-base text-xs cursor-pointer py-4 px-4 border-b-2"
                      >
                        {item.type}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="questionsAndAnswers w-full text-black">
            <ul className="flex flex-col gap-4 ">
              {questionsAndAnswers.map((itm, idx) => (
                <li key={idx} className="">
                  <h3
                    className="border-2 flex items-center justify-between px-4 py-2 rounded-md whitespace-nowrap cursor-pointer"
                    style={{ color: "#483B6E", backgroundColor: "#E6E6F9" }}
                    onClick={() => toggleAccordion(idx)}
                  >
                    {itm.question}
                    <span
                      className={expandedAccordion === idx ? "rotate-180" : ""}
                    >
                      <img src={expand} alt="" />
                    </span>
                   </h3>

                  {expandedAccordion === idx && (
                    <p className="px-4 py-2" style={{ fontWeight: "200" }}>
                      {itm.answer}
                    </p>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
