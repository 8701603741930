import React, { useEffect, useState } from "react";
import Hero from "./Hero/index";
import CustomersExp from "./CustomersExp";
import OurProducts from "./OurProducts";
import CustomersReviews from "./CustomersReviews";
// import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import NewsLetter from "../../components/Resuseables/NewsLetter";

const HomePage = () => {
  const [newState, setNewState] = useState(false)

  useEffect(() => {

    setTimeout(() => {
      setNewState(true)

    }, 10000);
  }, [])

  const closeModal = () => {
    setNewState(false)
  }



  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
      {/* <Layout> */}
      {newState && <NewsLetter handleFun={closeModal} />}
      <Header />
      <Hero />
      <CustomersExp />
      <OurProducts />
      <CustomersReviews />
      <Footer />
      {/* </Layout> */}
    </section>
  );
};

export default HomePage;
