import React, { useEffect } from "react";
import HeroLogo from "../../../components/svgs/HeroLogo";
import herolady from "../../../assets/images/herolady.png";
import Ourpartners from "./Ourpartners";
import BluePaperPlane from "../../../components/svgs/BluePaperPlane";
import RedPaperPlane from "../../../components/svgs/RedPaperPlane";
import CurlyPlane from "../../../components/svgs/CurlyPlane";
import heroImgSmall from "../../../assets/images/green.png";
import Button from "../../../components/Resuseables/Button/Button";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="hero-container  w-full h-full flex flex-col ">
        <div className="relative flex  gap-28 md:gap-0 flex-col md:flex-row justify-center pt-8  px-4  sm:px-10 xl:px-28 ">
          <div className="heroBox-1 relative w-full h-full flex flex-col items-center justify-center">
            <div
              className="heroBox1-content w-full xl:w-3/4 h-full  flex flex-col align-center justify-center gap-8 text-center md:text-left pb-10 md:pb-0"
              style={{ color: "#0050AE" }}
            >
              <h1 className="text-3xl lg:text-4xl xl:text-5xl font-extrabold ">
                <div className="items-center justify-between hidden md:flex">
                  <BluePaperPlane />

                  <RedPaperPlane />
                </div>
                Reach Your Customers Without Barriers
              </h1>

              <div>
                <img src={heroImgSmall} alt="" className="md:hidden" />
              </div>

              <div className="flex flex-col gap-6">
                <p className="font-semibold">
                  Sleengshort redefines communication, offering a diverse suite
                  of services designed to transcend the boundaries of time and
                  space.{" "}
                </p>
                <p className="font-thin">
                  From Bulk SMS, Voice, Two-way SMS, to Call Center and USSD
                  solutions, we equip you with the tools to engage your audience
                  effortlessly.
                </p>
              </div>

              <div>
                <Link
                  to={"https://app.sleengshort.com/login"}
                  className="flex items-center gap-10 justify-center  md:justify-start"
                >
                  <Button
                    btnText="Start Here"
                    btnBgColor="#0050ae"
                    btnColor="#fff"
                    btnRadius="28px"
                    btnPadding="4px 40px 4px 40px"
                  />

                  <div className="hidden md:block">
                    <CurlyPlane />
                  </div>
                </Link>
              </div>
            </div>

       
          </div>

          <div className="heroBox-2 relative  h-full  w-full hidden md:block">
            <img src={herolady} alt="" />

            <div className="hero-logo absolute right-12 top-10 -z-50">
              <HeroLogo />
            </div>
          </div>
        </div>

        <Ourpartners />
      </section>
    </>
  );
};

export default Hero;
