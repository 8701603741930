import React from "react";
import ProducSubHead from "../../../../components/Resuseables/ProductSubHead/ProducSubHead";
import ProductCards from "../../../../components/Resuseables/ProductCards/ProductCards";
import { voiceContent } from "../../../../mock/data";
// import ProductPricing from "../../../../components/Resuseables/ProductPricing/ProductPricing";
// import Button from "../../../../components/Resuseables/Button/Button";

const VoiceContent = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-10">
      <ProducSubHead
        desc={"Elevate User Interaction with Sleengshort."}
        title={"Use Case :"}
      />

      <div className="w-4/5 flex items-center justify-center">
        <div className="w-full sm:grid grid-cols-2 grid-rows-2 flex flex-col justify-center gap-10 ">
          {voiceContent.map((card, index) => (
            <ProductCards
              key={index}
              title={card.tag}
              desc={card.desc}
              icon={card.icon}
            />
          ))}
        </div>
      </div>

      {/* 
      <ProducSubHead title={"Pricing"} />

      <ProductPricing
        title={"Promotional Marketing Messages (Naira)"}
        title2={"Transactional/Corporate Messages (Naira)"}
      />

      <Button
        btnText={"Get Started"}
        btnBgColor={"#0050AE"}
        btnColor={"white"}
        btnRadius={"19px"}
        btnPadding={"8px 28px"}
      /> */}
    </div>
  );
};

export default VoiceContent;
