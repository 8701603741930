import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SectionHeading from '../../components/Resuseables/Subheading/SectionHeading';
import OurStory from './OurStory';
import OurMission from './OurMission';
import OurVision from './OurVision';

const AboutPage = () => {
  return (
 <section className="w-full h-screen flex flex-col overflow-x-hidden">
  <Header />
  <div className='my-20'>
  <SectionHeading title={'About Us'} color={'#0050ae'} />
  </div>


  <OurStory />
  <OurMission />
  <OurVision />
  <Footer />
 </section>
  )
}

export default AboutPage;