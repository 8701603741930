import React from "react";

const CloseMobileNav = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="34" height="34" fill="url(#pattern0_2493_183)" />
      <defs>
        <pattern
          id="pattern0_2493_183"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_2493_183" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_2493_183"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFOUlEQVR4nO2dy49URRSHS0eQvwFG8L3ysWCjiQ9AJ3Htg6Au3PsIMZKQqJiJgJmYuGBjTETJ9Eyb3AR27jC6QEWNC3yHBaJux5n4HBgQPnLCmXCR7uHevnXr1utLetMz3VW3fl11qk6dOmVMJpPJZDKRAqwB7ga2Aq8CfeAo8C1wAlgAlvS1oO/J3z4HZvUzT+h3XN/18wQHcB2wEdgJHAZOYY+zwNfAFPBwFmi4CNcCDwHTwF+440/gALBZ6mBSB7gBeAP4je75FdgLjJvUAG4C9lkejmxxBugBt5tEhBCj/B/+cxaYATaY2ABWAduBvwmPRWAymgmAzmaOEz4/AVtM4NNX+WWdIx7Oq+1bbQKcPX1GvHwl9tCEAPCIrphj53dgwvgM8LROG1NhCXjS+AjwQmT2oo5d2WF8Ani9cvXjZdL4APBc1y3hES92LcZTiQ5TKw1fz3Q5m0rJgNcx9BNdrDNk2pcZzIIzH5j6pWJe9NniC2krF4K8Za3K8TPlwlEohitTDWmrTW2JsVo9npl6HG/FdQ/sqlmRzCV22hZjA/BPqYBMPf4FbrQpyAc1K5C5kmlbYtyi+8uZZkgcwa02BNnfsCKZS7xjY0UuroCMHcTVtL6JIBLE1jYfAmv1dRD3HCyVL3Vpmz1NwjtdRBSuLZU5pgHSrpCyxkrlr3NQ5i8jha1qrC0uBXEsymViOBRE2GzqooHProaMMceizA4p09WQ+f4obhKXUeizDkWZ7eAHMCjqvronGLgf9xQSXDegoXqBlVGVe+sI8hrdULTYYD6JIbxSR5BP6I6ihYbzTQzhozpn+k7TLf0h43y/4++yyalKbnk9HOkDhYVftY89o8wdVQSR066+UDRoUN/FEB7z2aDbbNhhn5ETUT5xdcPuwdjaVJRQxBB6VQTxNcSnX8E4+2rAh3GkiiDf4S/FCj0lpJ6xzDdVBDmJ3/SH9IKQesYyP1cRZB7/Kf7fGwLrGcvMVREklB3CYpAoAYkhnE5FkB4RCRLLkNUjkiErG3XPjHqI096ZQFwlI017P8VP+qkuDF1uZValqLHoC6mnTIcY5V6MsAIPRZSXY3G/zzT4bHDud983qGYsfEdQG1RrPEi9109gC3ex8skq4OMOK1q04LX1saccriRGx4a9SCgM6OoGvVTR+zqoYJFYoNw9dZMD5FDS9vhjJV/cMFEk47MLUgy23l9LDK3gFkeVS/E4woOjHtiR9Nttk9qBnZPANbUF0QpKLvS2kWNk43qe8RDuOVQq38WRtt0jiaGCjAe0gxgCEjO9bmRBVJR3u36KiHi7kRgqyM05cYC1I9F2ki8HFMXhMwesiKGCrM/JZxonn7Gb8k98L83qlDQ7rIpROpn7Y9dPFiA/tJZ7UVfvOcVfdSSn8QOtiFES5c0aFUqdva2KUbqoxddQIZ846iRNbGkFP9f1E3vMfKM0TCOKMpHdKgNZ6uyuKmBbTsZ/hRHf2okYJVGevbxOSbPd+IDexpY6u4xPAM8nOnydB14yPgI86kGeFNcGfJvxGZ19pXC3yFwwN3/qOuUI8fKl1dThLoj86tVVJlTk/oxIvMTft+4o7OD6bpfRkDY3lyaDu5C4xs7jdCDJ/c9o9Oa4iR29j2SfB+dQhk1lJfj6NpMaOhvb4yhCskoK8N2N46ZiQMNWN0nGZ00y7DIK/T2JtR05vDN2uBjfu1HubZJTRnr0yxaLurk2pTfOxWeo24aLwRV3AY9LjkId36VRjwEndDNoSV/z+t4x/Z+eRsvIZ+/MAmQymUzGRMwFiMdwAEb1bS0AAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};

export default CloseMobileNav;
