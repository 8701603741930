import React from 'react'
import ProductHeader from '../../../../components/Resuseables/ProductsHeader/ProductHeader';
import voiceImg from "../../../../assets/images/voicehead.png"

const VoiceTop = () => {
  return (
    <div className="w-full flex flex-col items-center  gap-10 py-28 text-white">
    <ProductHeader title={"AIRTIME"} headerImg={voiceImg} />
   
  </div>
  )
}

export default VoiceTop;