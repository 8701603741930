import React from 'react'
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BulksmsTop from './BulksmsTop';
import BulksmsContent from './BulksmsContent/';

const BulkSMSPage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
    <Header />
    <BulksmsTop />
    <BulksmsContent />
    <Footer />
  </section>
  )
}

export default BulkSMSPage;