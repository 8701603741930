import React from "react";
import bgImg from "../../../assets/images/footerbg.png";

const ProductHeader = ({ title, headerImg }) => {
  return (
    <div
      // className="w-full h-[281px] flex items-center justify-center gap-10 sm:px-10 xl:px-28 py-28 text-white relative"
      className="w-full h-[281px] flex items-center justify-center gap-10 sm:px-10 xl:px-28 py-28 text-white relative"

      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full  h-auto flex items-center justify-between   relative">
        <h1 className="w-full text-center text-3xl md:text-[40px] lg:text-[70px]">
          {title}
        </h1>

        <div className="imageWrapper w-full h-[280px]  ">
          <img src={headerImg} alt="" className="w-full h-full lg:object-contain" />
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
