import React, { useState } from 'react'
import SectionHeading from '../../../components/Resuseables/Subheading/SectionHeading';
import bgImg from "../../../assets/images/group-people.png";
import Button from '../../../components/Resuseables/Button/Button';
import axios from 'axios';



const QueriesAndComplaints = () => {

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
 



  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
   

      const response = await axios.post(
        "http://api.tapestry.ng:1234/api/v1/sleeng/sendForm",  
        { fullName, email, phone, subject, message },
        {
          headers: {
            "Content-Type": "application/json",
            "api-auth-key": 'sleenghort_1234567890987654321',
          },
        }
      );

       if (response.status === 200) {
           alert("your request has been submitted")
      } else {
        alert("Failed to send message. Please enter a valid email and try again.");
      }
    } catch (error) {
      console.error("Error:", error);
     } 
  };


  return (
    <div
    className="w-full  flex flex-col items-center justify-center px-4 sm:px-10 xl:px-28 py-10 text-white"
    style={{
      backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgImg})`,  
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }} >


    <div className="w-full lg:w-4/5 flex flex-col gap-10 items-center justify-center">
      <div className="w-full">
        <SectionHeading title={"Leave Us A Message"} subtitle={"For Any Queries or Complaints"} />
      </div>

      <form action="" onSubmit={handleFormSubmit} className='lg:w-2/3 flex flex-col  justify-center gap-6 md:gap-10 text-black'>
        <div className='w-full flex items-center gap-6 md:gap-10'>
          <input type="text" placeholder='Full Name' className='w-full px-4 py-2 outline-none'  value={fullName} onChange={(e) => setFullName(e.target.value)} required/>
          <input type="email" placeholder='Email Address'className='w-full px-4 py-2 outline-none' value={email} onChange={(e) => setEmail(e.target.value)} required/>
        </div>

        <div className='w-full flex items-center gap-6 md:gap-10'>
          <input type="text" placeholder='Phone Number' className='w-full px-4 py-2 outline-none'  value={phone} onChange={(e) => setPhone(e.target.value)} required/>
          <input type="text" placeholder='Subject' className='w-full px-4 py-2 outline-none'  value={subject} onChange={(e) => setSubject(e.target.value)} required/>
        </div>

        <div>
          <textarea name="" id="" cols="30" rows="10" placeholder='Enter your message' className='w-full  px-4 py-2 outline-none'  value={message}onChange={(e) => setMessage(e.target.value)} required></textarea>


          <div className='w-full flex items-center gap-2 text-sm text-white'>
          <input type="checkbox" name="" id="" />
          <p>I consent to my information being stored in relation to dealing with my enquiry. <span style={{textDecoration:'underline', cursor:'pointer'}}>Learn more about our Privacy Policy </span></p>
        </div>
        </div>
        
        <div className='flex justify-center cursor-pointer'>
        <Button
          btnText="Send Message"
          btnBgColor="#0050ae"
          btnColor="#fff"
          btnRadius="5px"
          btnPadding="4px 40px 4px 40px"
        />
        </div>
      
        <div>

        </div>
      </form>
      </div>

      </div>
  )
}

export default QueriesAndComplaints;