import heroLogo from "../../assets/icons/heroLogo.svg"







const HeroLogo = () => {
  return (
   <img src={heroLogo} alt="" />

  );
};

export default HeroLogo;
