import React, { useState } from "react";
import Hamburger from "../../svgs/Hamburger";
import MobileLogo from "../../svgs/MobileLogo";
import { Link } from "react-router-dom";
import { navLinks, productLinks } from "../../../mock/data";
import Button from "../../Resuseables/Button/Button";
import RightArrIcon from "../../svgs/RightArrIcon";
import Whitedropdown from "../../svgs/Whitedropdown";
import CloseMobileNav from "../../svgs/CloseMobileNav";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [showDropDown, setShowDropDown] = useState(false);

  const handleDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <div className="lg:hidden relative w-full   px-4  sm:px-10 xl:px-28 ">
      <div className="w-full flex items-center justify-between ">
        <Link to="/" className="w-auto relative">
          <div className="flex items-center">
            <MobileLogo />
          </div>
        </Link>

        <button onClick={toggleMenu}>
          <Hamburger />
        </button>
      </div>

      {isOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-[#0050ae] bg-opacity-50 z-50 backdrop-blur-sm   "
            onClick={toggleMenu} 
          ></div>
          <div
            className={`w-full h-full flex flex-col bg-peach-600 fixed top-0 left-0 z-50 transition-all duration-300  ease-in-out transform 
            ${isOpen ? "translate-x-0" : "-translate-x-full"}`}
          ></div>


          <div className="fixed top-0 left-0 w-full h-[500px] flex items-center justify-center z-50  ">
            <div className=" absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-3xl bg-blue-500 bg-opacity-90 flex flex-col  items-center justify-center">
              <div className="flex flex-col gap-5 items-center justify-center">
                <div onClick={toggleMenu}>
                  <CloseMobileNav />
                </div>
                <nav className="">
                  <ul
                    className="flex flex-col gap-6 xl:gap-10 font-medium"
                    style={{ color: "white" }}
                  >
                    {navLinks.map((items, idx) => (
                      <li
                        key={idx}
                        className="relative cursor-pointer flex items-center justify-center gap-4 font-bold"
                        onClick={idx === 1 ? handleDropdown : null}
                      >
                        <Link to={items.url}>{items.label}</Link>
                        {idx === 1 && (
                          <span onClick={handleDropdown}>
                            <Whitedropdown />
                          </span>
                        )}

                        {idx === 1 && showDropDown && (
                          <div className="shadowBg absolute w-full h-[150px] top-full  z-50 bg-white text-[#0050ae] rounded">
                            <div className="h-full flex flex-col p-2  justify-between items-center">
                              {productLinks.map((link, index) => (
                                <p
                                  key={index}
                                  className="cursor-pointer hover:underline"
                                >
                                  <Link to={link.url}>{link.label}</Link>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>

                <div className="flex flex-col items-center justify-center font-bold gap-4 ">
                  <Link to="https://app.sleengshort.com/login">
                    <Button btnText="Log in" btnColor={"white"} />
                  </Link>
                  <div className="shadowBg bg-[#5FBA46] text-white rounded px-5 py-2 flex items-center gap-5">
                    <span>
                      <RightArrIcon />
                    </span>
                    <Link to="https://app.sleengshort.com/register">
                      <Button btnText="Sign up" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileMenu;
