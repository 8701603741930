import React from "react";
import ProductHeader from "../../../../components/Resuseables/ProductsHeader/ProductHeader";
import smsImg from "../../../../assets/images/bulksmshead.png";
// import ProducSubHead from "../../../../components/Resuseables/ProductSubHead/ProducSubHead";

const BulksmsTop = () => {
  return (
    <div className="w-full flex flex-col items-center  gap-10 py-28 text-white">
      <ProductHeader title={"BULK SMS"} headerImg={smsImg} />
     
    </div>
  );
};

export default BulksmsTop;
