import React from 'react'
import { ourProducts } from '../../../mock/data'
import SectionHeading from '../../../components/Resuseables/Subheading/SectionHeading'

const OurProducts = () => {
  return (
  <section className='ourProduct w-full h-auto text-center px-4 sm:px-10 xl:px-28 py-20'>

  {/* <h1 className='text-white text-4xl '>Our Products</h1> */}
  <SectionHeading title={'Our Product'} color={'#fff'}/>
  

  <div className='w-full h-auto flex flex-col items-center justify-center pt-20'>
  <div className='grid  sm:grid-cols-2 sm:grid-rows-2 md:grid-cols-none md:flex flex-wrap justify-center items-center gap-10'>
    {ourProducts.map((item, idx) => (
      <div className='w-[260px] min-h-[315px] bg-white rounded-2xl text-left px-8 flex flex-col items-start gap-4 pt-8' key={idx}>
        <img src={item.icon} alt="" className='w-12 h-12'/>
        <h4 className='text-3xl font-bold ' style={{color:'#0050ae'}}>{item.tag}</h4>
        <p className='text-sm font-medium'>{item.desc}</p>
      </div>
    ))}
  </div>

{/* <div className='grid  grid-cols-2 grid-rows-2 md:grid-cols-none md:flex flex-wrap justify-center items-center gap-5 sm:gap-10'>
    {ourProducts.map((item, idx) => (
      <div className='w-[160px] max-h-[260px] sm:w-[260px] sm:min-h-[315px] bg-white rounded-2xl text-left px-8 flex flex-col items-start gap-4 pt-8' key={idx}>
        <img src={item.icon} alt="" className='w-12 h-12'/>
        <h4 className='text-3xl font-bold ' style={{color:'#0050ae'}}>{item.tag}</h4>
        <p className='text-sm font-thin'>{item.desc}</p>
      </div>
    ))}
  </div> */}

</div>
  </section>
  )
}

export default OurProducts;