import React from "react";
import MainRouter from "./router";

 

const App = () => {
  return (
    <main className="">
    <MainRouter />
    </main>

  );
};

export default App;
