import React, { useState } from "react";
import { reviews } from "../../../mock/data";
import heroLady from "../../../assets/images/heroVid.png";
import Button from "../../../components/Resuseables/Button/Button";
import BluePaperPlane from "../../../components/svgs/BluePaperPlane";
import RedPaperPlane from "../../../components/svgs/RedPaperPlane";
import CurlyPlane from "../../../components/svgs/CurlyPlane";
import ladyBg from "../../../assets/images/womanBg.png";
import { Link } from "react-router-dom";
import VideoPlayer from "../VideoPlayer";

const CustomersReviews = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section className="w-full h-full text-center mb-[25rem] sm:mb-0 md:pt-10  flex flex-col gap-20 ">
      <div
        className="w-4/5 h-full flex flex-col lg:flex-row justify-center gap-10  mx-auto   my-20 lg:my-28"
        style={{ color: "#0050ae" }}
      >
        <div className="max-w-2xl flex flex-col gap-10  md:text-left ">
          <h1 className="md:text-[40px] text-3xl font-bold">
            Happy Customers are Our True Wealth
          </h1>
          <div className="flex flex-col gap-5">
            <p className="font-bold">
              From Communication Challenges to Success Stories: Discover How
              MONI Transformed Their Business with Sleengshort!
            </p>
            <p>
              Our valued client shares their journey, highlighting how
              Sleengshort's innovative solutions revolutionized their
              communication strategy, boosted engagement, and drove impressive
              results.
            </p>
          </div>

          <div>
            <Link to="https://app.sleengshort.com/login">
              <Button
                btnText="Start Here"
                btnBgColor="#0050ae"
                btnColor="#fff"
                btnRadius="28px"
                btnPadding="4px 40px 4px 40px"
              />
            </Link>
          </div>
        </div>

  

        <div
          className="ladyReview w-full relative hover:cursor-pointer"
          onClick={() => setShowVideo(true)}
        >
          <img src={heroLady} alt="" />

          <div className="arr-2 absolute top-0 right-0 md:right-20 ">
            <RedPaperPlane />
          </div>

          <div className="arr-1 absolute  right-0 ">
            <BluePaperPlane />
          </div>

          <div className="arr-3 absolute left-0  ">
            <CurlyPlane />
          </div>
        </div>

        {showVideo && (
          <div
            className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
            onClick={() => setShowVideo(false)}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-300  rounded-lg shadow-lg sm:max-w-3xl w-full">
              {/* <h1 className="absolute z-0 top-1/2 left-1/2">Loading video.....</h1> */}
              <VideoPlayer />
            </div>
          </div>
        )}
      </div>

      <div className="slider-wraper w-full flex flex-col gap-14">
        {/*___________________________  group 1 _____________________________*/}
        <div className="slider w-full m-auto overflow-hidden">
          <div className="slider-track left-to-right w-full flex gap-6 cursor-grab">
            {reviews.map((item, idx) => (
              <div
                className="reviews min-w-96 min-h-44 md:min-h-60 flex flex-col justify-between px-4 pt-4 rounded-2xl text-left text-xs"
                key={idx}
                style={{ backgroundColor: "#F0F0F0" }}
              >
                <div>{item.comment}</div>

                <div className="flex justify-between items-center">
                  <div className="flex gap-4 items-center">
                    <div className="w-12 h-12 rounded-full">
                      <img src={item.img} alt="" className="rounded-full" />
                    </div>

                    <div className=" py-2">
                      <p style={{ color: "#FF0000" }}>{item.name}</p>
                      <p>{item.job}</p>
                    </div>
                  </div>

                  <div className="stars">
                    <img src={item.star} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/*_______________________________ group two _________________________________*/}
        {/* <div className="slider w-full m-auto overflow-hidden">
          <div className="slider-track right-to-left w-full flex gap-6 cursor-grab">
            {reviews.map((item, idx) => (
              <div
                className="reviews min-w-96 min-h-44 md:min-h-60 flex flex-col justify-between px-4 pt-4 rounded-2xl text-left text-xs"
                key={idx}
                style={{ backgroundColor: "#F0F0F0" }}
              >
                <div>{item.comment}</div>

                <div className="flex justify-between items-center">
                  <div className="flex gap-4 items-center">
                    <div className="w-12 h-12">
                      <img src={item.img} alt="" className="rounded-full" />
                    </div>

                    <div className=" ">
                      <p style={{ color: "#FF0000" }}>{item.name}</p>
                      <p>{item.job}</p>
                    </div>
                  </div>

                  <div className="stars">
                    <img src={item.star} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default CustomersReviews;
