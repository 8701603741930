import React from 'react'
import Header from "../../../components/Header";
import Footer from '../../../components/Footer';
import AirtimeTop from './AirtimeTop';
import AirtimeContent from './AirtimeContent';

const AirtimePage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden ">
    <Header />
    <AirtimeTop />
    <AirtimeContent />
    <Footer/>
  </section>
  )
}

export default AirtimePage;