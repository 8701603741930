import React from 'react'
import ProductHeader from '../../../../components/Resuseables/ProductsHeader/ProductHeader';
import usdImg from "../../../../assets/images/usdhead.png";


const USDTop = () => {
  return (
    <div className="w-full flex flex-col items-center  gap-10 py-28 text-white">
    <ProductHeader title={"USSD"} headerImg={usdImg} />
   
  </div>
  )
}

export default USDTop;