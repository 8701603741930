import React from "react";
import bluePlane from "../../assets/icons/bluePlane.svg";





const BluePaperPlane = () => {
  return (
  <img src={bluePlane} alt="" />
  );
};

export default BluePaperPlane;
