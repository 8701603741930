import React from "react";

const ProductCards = ({ icon, title, desc }) => {
  return (
    <div
      className="shadowBg px-4 md:px-8 py-4 h-auto rounded-md flex flex-col justify-center bg-[#F0F0F0] md:bg-[#DCE7FE] text-[#0050ae]"
    >
      <div className="flex flex-col gap-10 ">
        <div className="flex items-center">
          <img src={icon} alt="" className="w-[35px]" />
          <h4 className="text-sm md:text-md lg:text-xl font-bold whitespace-nowrap">
            {title}
          </h4>
        </div>

        <div>
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
