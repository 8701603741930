 import React from 'react'
import ReactPlayer from 'react-player'
 
 const VideoPlayer = () => {
   return (
    <div className='h-auto w-full z-50'>

     <ReactPlayer 
     url={"https://www.youtube.com/watch?v=FhkkAlESo-Y"}
     controls
     className="w-full h-full"
     width="100%"
     />
    </div>

   )
 }
 
 export default VideoPlayer;