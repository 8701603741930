import React from 'react'
import Header from "../../../components/Header";
import Footer from '../../../components/Footer';
import USDTop from './USDTop';
import USDContent from './USDContent';



const UsdPage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
    <Header />
    <USDTop />
    <USDContent />
    <Footer/>
  </section>
  )
}

export default UsdPage;