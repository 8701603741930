import React from "react";

const MonLogo = () => {
  return (
    <svg
      width="147"
      height="63"
      viewBox="0 0 147 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.481 44.3119V24.668H53.943L54.3774 27.3216C55.0137 26.3675 55.8748 25.5856 56.8844 25.0449C57.9983 24.4596 59.2418 24.1665 60.499 24.1931C63.5259 24.1931 65.6714 25.3707 66.9356 27.7258C67.6538 26.6358 68.64 25.7496 69.7987 25.1529C71.0097 24.5153 72.3591 24.1883 73.7265 24.2009C76.2811 24.2009 78.2427 24.967 79.6113 26.4992C80.9799 28.0313 81.6643 30.2753 81.6643 33.231V44.3197H76.6092V33.6981C76.6092 32.0089 76.287 30.7155 75.6424 29.818C74.998 28.9204 74.0044 28.471 72.662 28.4697C71.292 28.4697 70.1928 28.9714 69.3643 29.975C68.5359 30.9786 68.1203 32.378 68.1177 34.1731V44.3119H63.0646V33.6981C63.0646 32.0089 62.7385 30.7155 62.0861 29.818C61.4338 28.9204 60.4201 28.471 59.045 28.4697C58.4231 28.4516 57.8056 28.5781 57.2407 28.8393C56.6757 29.1005 56.1786 29.4892 55.7885 29.975C54.9613 30.9799 54.5471 32.3792 54.5458 34.1731V44.3119H49.481Z"
        fill="#202325"
      />
      <path
        d="M93.7333 44.7852C91.9434 44.8128 90.1785 44.3623 88.6196 43.4801C87.1177 42.6189 85.8762 41.3663 85.0264 39.8551C84.1445 38.3112 83.7035 36.5226 83.7035 34.4893C83.7035 32.4561 84.151 30.6674 85.046 29.1235C85.9075 27.6132 87.157 26.362 88.6646 25.5005C90.2263 24.643 91.9782 24.1935 93.7587 24.1935C95.5392 24.1935 97.2911 24.643 98.8528 25.5005C100.355 26.3607 101.596 27.6126 102.446 29.1235C103.327 30.6674 103.767 32.4561 103.769 34.4893C103.77 36.5226 103.329 38.3112 102.446 39.8551C101.593 41.3711 100.343 42.6245 98.8313 43.4801C97.2765 44.3584 95.5176 44.8088 93.7333 44.7852ZM93.7333 40.3909C94.3772 40.3938 95.0148 40.2634 95.6061 40.0078C96.1975 39.7521 96.7298 39.3768 97.1698 38.9051C98.1444 37.916 98.6317 36.4441 98.6317 34.4893C98.6317 32.5346 98.1444 31.0625 97.1698 30.0734C96.1952 29.0842 95.0633 28.5897 93.7744 28.5897C93.1271 28.5825 92.4854 28.7107 91.8903 28.9661C91.2952 29.2217 90.7597 29.5988 90.3182 30.0734C89.3567 31.0639 88.8765 32.5358 88.8779 34.4893C88.8791 36.4427 89.3592 37.9147 90.3182 38.9051C90.7533 39.3771 91.2816 39.753 91.8696 40.0089C92.4577 40.2647 93.0923 40.3947 93.7333 40.3909Z"
        fill="#202325"
      />
      <path
        d="M105.803 44.3123V24.6684H110.267L110.659 27.9951C111.258 26.8425 112.168 25.8823 113.285 25.2238C114.429 24.537 115.778 24.1935 117.332 24.1935C119.755 24.1935 121.638 24.9596 122.98 26.4918C124.323 28.0239 124.994 30.2677 124.994 33.2234V44.3123H119.941V33.6984C119.941 32.0093 119.599 30.7159 118.916 29.8183C118.232 28.9208 117.165 28.4713 115.716 28.4701C115.059 28.4505 114.406 28.5752 113.802 28.8351C113.199 29.0952 112.659 29.4843 112.221 29.9754C111.307 30.9803 110.851 32.3796 110.851 34.1733V44.3123H105.803Z"
        fill="#202325"
      />
      <path
        d="M130.047 21.6202C129.212 21.6511 128.396 21.3525 127.777 20.788C127.488 20.5222 127.259 20.1972 127.106 19.8349C126.951 19.4725 126.879 19.0814 126.889 18.6881C126.877 18.2977 126.951 17.9096 127.105 17.5507C127.258 17.1918 127.488 16.8706 127.777 16.6096C128.418 16.0843 129.22 15.7974 130.048 15.7974C130.876 15.7974 131.678 16.0843 132.319 16.6096C132.609 16.8706 132.839 17.1918 132.992 17.5507C133.146 17.9096 133.22 18.2977 133.208 18.6881C133.218 19.0814 133.144 19.4725 132.991 19.8349C132.838 20.1972 132.609 20.5222 132.319 20.788C131.699 21.3525 130.884 21.6512 130.047 21.6202ZM127.521 44.3119V24.6681H132.576V44.3119H127.521Z"
        fill="#202325"
      />
      <path
        d="M9.40257 34.7382L9.34581 34.6912C8.03638 33.6164 6.35763 33.102 4.67283 33.2592C2.98805 33.4165 1.43274 34.2326 0.343506 35.5311L5.21063 39.6173L5.26738 39.6644C6.57681 40.7392 8.25556 41.2535 9.94036 41.0964C11.6251 40.9392 13.1805 40.1229 14.2696 38.8244L9.40257 34.7382Z"
        fill="#E66652"
      />
      <path
        d="M11.5042 27.5825L11.4905 27.51C11.1756 25.8434 10.2182 24.3685 8.82606 23.4051C7.43392 22.4419 5.71923 22.0678 4.05383 22.3639L5.1576 28.6266L5.16934 28.6993C5.48478 30.3655 6.44224 31.8401 7.83426 32.8033C9.22627 33.7665 10.9407 34.1408 12.606 33.8452L11.5042 27.5825Z"
        fill="#E66652"
      />
      <path
        d="M17.6983 23.4593L17.7355 23.3964C18.5646 21.9206 18.779 20.1761 18.3318 18.5427C17.8847 16.9092 16.8122 15.5189 15.348 14.6746L12.1698 20.1817L12.1326 20.2445C11.3034 21.7203 11.0891 23.4647 11.5363 25.0982C11.9835 26.7318 13.0559 28.1219 14.5201 28.9664L17.6983 23.4593Z"
        fill="#E66652"
      />
      <path
        d="M25.0964 24.2871L25.1668 24.2616C26.749 23.6673 28.0333 22.4707 28.7403 20.9318C29.4473 19.3929 29.5197 17.6362 28.942 16.0441L22.9671 18.2167L22.9084 18.252C21.3262 18.8462 20.0421 20.043 19.3355 21.582C18.6287 23.121 18.5569 24.8776 19.1353 26.4695L25.0964 24.2871Z"
        fill="#E66652"
      />
      <path
        d="M30.2324 29.6788L30.3008 29.7044C31.8961 30.2666 33.6479 30.176 35.1772 29.4524C36.7064 28.7288 37.8899 27.4303 38.4714 25.8379L32.4986 23.6595L32.4301 23.636C30.8348 23.0732 29.0828 23.1635 27.5535 23.8871C26.024 24.6108 24.8406 25.9096 24.2595 27.5023L30.2324 29.6788Z"
        fill="#E66652"
      />
      <path
        d="M30.7014 37.1127L30.7386 37.1755C31.6039 38.6331 33.0064 39.6908 34.643 40.1198C36.2796 40.5487 38.0187 40.3145 39.4846 39.4679L36.3064 33.953L36.2692 33.8901C35.4047 32.4316 34.0021 31.3734 32.3653 30.9443C30.7283 30.5153 28.9888 30.7501 27.5232 31.5978L30.7014 37.1127Z"
        fill="#E66652"
      />
      <path
        d="M26.2867 43.1089L26.273 43.1815C25.9992 44.8537 26.3945 46.5668 27.3729 47.9486C28.3512 49.3303 29.8337 50.269 31.4983 50.5609L32.602 44.2982L32.6157 44.2256C32.8895 42.5534 32.4942 40.8403 31.5159 39.4585C30.5375 38.0768 29.0551 37.1381 27.3905 36.8462L26.2867 43.1089Z"
        fill="#E66652"
      />
      <path
        d="M19.0535 44.8615L18.9967 44.9087C17.713 46.0098 16.9151 47.5751 16.7769 49.2635C16.6386 50.9519 17.1714 52.6267 18.2589 53.923L23.128 49.8349L23.1828 49.7878C24.466 48.6866 25.2634 47.1219 25.4016 45.434C25.5398 43.7459 25.0075 42.0716 23.9205 40.7755L19.0535 44.8615Z"
        fill="#E66652"
      />
      <path
        d="M12.3821 41.5524H12.3097C10.6196 41.5695 9.00443 42.2541 7.81447 43.4578C6.6245 44.6614 5.95573 46.287 5.95325 47.9821H12.3821C14.0721 47.9649 15.6873 47.2804 16.8773 46.0767C18.0672 44.873 18.736 43.2475 18.7385 41.5524H12.3821Z"
        fill="#E66652"
      />
    </svg>
  );
};

export default MonLogo;
