import React from "react";
import { customersExp } from "../../../mock/data";
import people from "../../../assets/images/people.png";
import Button from "../../../components/Resuseables/Button/Button";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";
import RedPaperPlane from "../../../components/svgs/RedPaperPlane";
import { Link } from "react-router-dom";

const CustomersExp = () => {
  return (
    <section className="w-full h-auto flex flex-col gap-10 pt-10 md:pt-28">
      <div className="relative w-full h-auto flex flex-col gap-10  px-4 sm:px-10 xl:px-28 pt-10 sm:pt-0">
        <SectionHeading
          title={"Enjoy The Best Customer Experience"}
          color={"#0050ae"}
        />

        <div
          className="relative flex flex-col justify-center items-center md:grid grid-cols-2 grid-rows-2 gap-10 w-full mx-auto py-8"
          style={{ color: "#0050ae" }}
        >
          {customersExp.map((items, idx) => (
            <div key={idx} className="flex justify-center gap-4">
              <img src={items.icon} alt="" className="w-10 h-10" />
              <div className="max-w-lg">
                <h4 className="font-bold">{items.title}</h4>
                <p>{items.desc}</p>
              </div>
            </div>
          ))}

          <div className="arr-2 hidden lg:block absolute top-0  right-0">
            <RedPaperPlane />
          </div>
        </div>
      </div>


      

      {/* <div className="w-full flex items-center justify-between gap-10 lg:gap-36 px-4 border-2 border-green-600"> */}
      <div className="w-full flex items-center lg:gap-6  px-4">
        <div className="imgContainer hidden md:block w-7/12">
          <img src={people} alt="" className="xl:w-full" />
        </div>

        <div
          className="contentContainer max-w-sm lg:max-w-3xl h-full flex flex-col items-center justify-center gap-2 lg:gap-6 md:items-start mx-auto   flex-1  "
          style={{ color: "#0050ae" }}
        >
          <h4 className="text-2xl lg:text-3xl font-semibold max-w-md">
            Communicate Across <br /> Multiple Channels, Devices And Networks
          </h4>
          <p className="pt-10 md:pt-2 max-w-sm">
            Technology is evolving and there are multiple communication channels
            available to customers. We provide our customers with access to
            these communication channels.
          </p>

          {/* <div className="py-10 md:pt-2 "> */}
          <div className="py-10">
            <Link to={"/about"}>
              <Button
                btnText="Find Out More"
                btnBgColor="#0050ae"
                btnColor="#fff"
                btnRadius="28px"
                btnPadding="4px 40px 4px 40px"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomersExp;
