import redPlane from "../../assets/icons/redPlane.svg";






const RedPaperPlane = () => {
  return (
    <img src={redPlane} alt="" />
  );
};

export default RedPaperPlane;
