import React from "react";

const GloLogoIcon = () => {
  return (
    <svg
      width="41"
      height="44"
      viewBox="0 0 41 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3294_85)">
        <path
          d="M20.4817 32.6502C28.295 32.6502 34.629 25.922 34.629 17.6224C34.629 9.32278 28.295 2.5946 20.4817 2.5946C12.6684 2.5946 6.33447 9.32278 6.33447 17.6224C6.33447 25.922 12.6684 32.6502 20.4817 32.6502Z"
          fill="#50B651"
        />
        <path
          opacity="0.663"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5861 17.5802C34.5861 25.9059 28.2323 32.6551 20.3962 32.6551C12.5601 32.6551 6.20459 25.9059 6.20459 17.5802C6.20459 13.8972 6.30177 13.3496 7.66653 11.2529C7.66653 11.2529 7.59611 13.6212 9.23094 15.0305C10.2475 15.9061 11.3084 15.274 13.2502 14.324C15.3509 13.2954 17.0586 12.6319 18.391 12.8069C20.398 13.071 26.5841 17.0173 29.8126 16.2315C32.3918 15.605 32.1196 9.14563 32.1196 9.14563C34.9364 12.0675 34.5861 14.0423 34.5861 17.5802Z"
          fill="url(#paint0_linear_3294_85)"
        />
        <path
          opacity="0.586"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4854 28.653C31.6179 23.5247 32.471 17.6496 29.0448 11.0277C29.0622 11.0411 29.2741 10.2642 29.6807 8.69678C31.5041 11.0424 32.4737 13.9376 32.5895 17.3823C32.5885 21.8441 30.5538 25.601 26.4854 28.653Z"
          fill="url(#paint1_linear_3294_85)"
        />
        <path
          opacity="0.744"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0515 8.72766C21.5741 7.27122 18.7319 4.34152 19.1097 3.72625C19.4875 3.11099 24.3913 3.21684 26.869 4.67327C29.3468 6.12971 31.4556 10.4987 31.0781 11.1139C30.7007 11.7292 26.5289 10.1841 24.0515 8.72766Z"
          fill="url(#paint2_radial_3294_85)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2092 21.3233C24.154 21.3233 22.4878 19.503 22.4878 17.2577C22.4878 15.0125 24.154 13.1925 26.2092 13.1925C28.2645 13.1925 29.931 15.0125 29.931 17.2577C29.931 19.503 28.2648 21.3233 26.2092 21.3233ZM26.2356 20.0337C27.5184 20.0337 28.5595 18.7908 28.5595 17.2577C28.5595 15.7246 27.5184 14.4829 26.2356 14.4829C24.9529 14.4829 23.9117 15.7257 23.9117 17.2585C23.9117 18.7912 24.9529 20.0337 26.2356 20.0337ZM21.2125 21.2781H19.7403V11.1649H18.5309V9.91907H21.2125V21.2781ZM16.5323 13.3081H17.9242V22.794C17.9242 23.6584 17.0682 24.5586 16.2341 24.9461C15.8397 25.1291 15.112 25.2378 14.051 25.2723V24.3286C15.0888 24.2057 15.7893 23.9604 16.1524 23.5929C16.3619 23.2836 16.4929 22.9221 16.5327 22.5434V19.7566C16.1606 20.5951 15.6921 21.151 15.1271 21.4243C14.5397 21.7609 13.3443 21.7056 12.8563 21.4243C12.3683 21.1431 11.6525 20.4025 11.3243 19.2995C11.2335 18.9951 11.0074 18.1666 11.031 17.2888C11.0521 16.4528 11.3127 15.5683 11.5292 15.2047C11.9011 14.5839 12.4873 13.8063 13.3753 13.4812C13.8091 13.3224 14.6131 13.2653 15.7873 13.3099V14.2484C14.9147 14.1858 14.3704 14.2171 14.1542 14.3422C13.8299 14.5292 13.0979 14.76 12.8563 16.4547C12.6148 18.1494 12.8634 19.5363 13.6292 19.9705C13.8915 20.1201 14.3556 20.297 14.7845 20.1411C15.6084 19.8418 16.3837 18.7748 16.4429 18.0518C16.5025 17.3182 16.5323 15.737 16.5323 13.3081Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1416 20.5975C32.8272 27.3205 27.2189 32.372 20.5003 32.372C12.8058 32.372 6.56787 25.7459 6.56787 17.5724C6.56787 12.4947 8.97522 8.01427 12.6435 5.34937C9.66288 7.97499 7.76503 11.9415 7.76503 16.377C7.76503 24.2887 13.8029 30.702 21.2507 30.702C27.3161 30.702 32.4455 26.449 34.1416 20.5975Z"
          fill="url(#paint3_radial_3294_85)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 0.0456543C11.3875 0.0456543 4 7.89262 4 17.5723C4 27.2519 11.3875 35.0993 20.5 35.0993C29.6125 35.0993 37.0001 27.2519 37.0001 17.5723C37.0001 7.89262 29.6125 0.0456543 20.5 0.0456543ZM20.5 32.3723C12.8055 32.3723 6.56756 25.7461 6.56756 17.5727C6.56756 12.4949 8.9749 8.01455 12.6431 5.34965C14.9566 3.66658 17.696 2.76806 20.5 2.77264C28.1946 2.77264 34.4325 9.39843 34.4325 17.5723C34.433 18.5886 34.3354 19.6022 34.1413 20.5974C32.8269 27.3204 27.2186 32.3719 20.5 32.3719V32.3723Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3294_85"
          x="0"
          y="0.0456543"
          width="41"
          height="43.0536"
          filterUnits="userSpaceOnUse"
          colorRIterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3294_85"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3294_85"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3294_85"
          x1="19.848"
          y1="11.2042"
          x2="21.8973"
          y2="28.6264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#123214" />
          <stop offset="0.46" stopColor="#3E7C37" />
          <stop offset="0.91" stopColor="#5FBB46" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3294_85"
          x1="30.3559"
          y1="10.0699"
          x2="30.3559"
          y2="24.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.64" stopColor="white" stopOpacity="0.43" />
          <stop offset="1" stopColor="white" stopOpacity="0.42" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_3294_85"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.3954 7.51894) rotate(28.1762) scale(7.0812 3.0533)"
        >
          <stop offset="0.13" stopColor="white" />
          <stop offset="0.29" stopColor="white" stopOpacity="0.69" />
          <stop offset="0.45" stopColor="white" stopOpacity="0.4" />
          <stop offset="0.59" stopColor="white" stopOpacity="0.18" />
          <stop offset="0.69" stopColor="white" stopOpacity="0.05" />
          <stop offset="0.74" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_3294_85"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.8896 14.1626) rotate(41.5501) scale(16.0737 17.6793)"
        >
          <stop offset="0.86" stopOpacity="0" />
          <stop offset="0.98" stopOpacity="0.47" />
          <stop offset="1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default GloLogoIcon;
