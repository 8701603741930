import React from "react";
import MTNlogo from "../../svgs/MTNlogo";
import AirtelLogo from "../../svgs/AirtelLogo";
import GloLogoIcon from "../../svgs/GloLogoIcon";
import NineMobile from "../../svgs/NineMobile";

export const tableContent = [{ id: "", title: "", tag: "" }];

const ProductPricing = ({title,title2}) => {
  return (
    <div className="w-full flex flex-col gap-10 md:gap-24">

      <div className="shadowBg rounded-md w-4/5  bg-[#DCE7FE]  mx-auto">
        <h2 className="py-5 md:ml-10 text-[#5FBA46] text-center md:text-left font-bold">
          {title}
        </h2>

        <div className="flex flex-col">
            <div className="inline-block min-w-full py-2">
              <div className=" overflow-x-auto ">

                <table className="min-w-full text-left text-sm font-light text-surface text-[#0050AE]">
                 
                  <thead className="font-medium whitespace-nowrap">
                    <tr>
                      <th scope="col" className="px-6 lg:px-14 py-4"> Network </th>
                      <th scope="col" className="px-6 py-4">0-200,000</th>
                      <th scope="col" className="px-6 py-4">201,000-1m</th>
                      <th scope="col" className="px-6 py-4">1m - 5m</th>
                      <th scope="col" className="px-6 py-4">5m - 10m </th>
                      <th scope="col" className="px-6 py-4">10m+</th>
                    </tr>
                  </thead>



                  <tbody className="font-semibold">
                    <tr className="bg-[white]">
                      <td className="whitespace-nowrap px-6 lg:px-14 py-4 font-medium flex items-center gap-2"><MTNlogo /><span>MTN</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.10</td>
                      <td className="whitespace-nowrap px-6 py-4">2.0</td>
                      <td className="whitespace-nowrap px-6 py-4">1.90</td>
                      <td className="whitespace-nowrap px-6 py-4">1.85</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>


                    <tr className="">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center gap-2"><AirtelLogo /> <span> Airtel</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.10</td>
                      <td className="whitespace-nowrap px-6 py-4">2.0</td>
                      <td className="whitespace-nowrap px-6 py-4">1.90</td>
                      <td className="whitespace-nowrap px-6 py-4">1.85</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>


                    <tr className="bg-[white]">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center gap-2"><GloLogoIcon /> <span> Glo</span> </td>
                      <td className="whitespace-nowrap px-6 py-4">2.10</td>
                      <td className="whitespace-nowrap px-6 py-4">2.0</td>
                      <td className="whitespace-nowrap px-6 py-4">1.90</td>
                      <td className="whitespace-nowrap px-6 py-4">1.85</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>

                    <tr className="border-b border-neutral-200 dark:border-white/10">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center"><NineMobile /> <span>9Mobile</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.10</td>
                      <td className="whitespace-nowrap px-6 py-4">2.0</td>
                      <td className="whitespace-nowrap px-6 py-4">1.90</td>
                      <td className="whitespace-nowrap px-6 py-4">1.85</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>










      <div className="shadowBg rounded-md w-4/5  bg-[#DCE7FE]  mx-auto ">
        <h2 className="py-5 md:ml-10 text-[#5FBA46] text-center md:text-left font-bold">
          {title2}
        </h2>

        <div className="flex flex-col">
            <div className="inline-block min-w-full py-2">
              <div className="overflow-x-auto">
                <table className="min-w-full text-left text-sm font-light text-surface text-[#0050AE]">
                 
                  <thead className="font-medium whitespace-nowrap">
                    <tr>
                      <th scope="col" className="px-6 lg:px-14  py-4"> Network </th>
                      <th scope="col" className="px-6 py-4">0-200,000</th>
                      <th scope="col" className="px-6 py-4">201,000-1m</th>
                      <th scope="col" className="px-6 py-4">1m - 5m</th>
                      <th scope="col" className="px-6 py-4">5m - 10m </th>
                      <th scope="col" className="px-6 py-4">10m+</th>
                    </tr>
                  </thead>



                  <tbody className="font-semibold">
                    <tr className="bg-[white]">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center gap-2"><MTNlogo /><span>MTN</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.80</td>
                      <td className="whitespace-nowrap px-6 py-4">2.70</td>
                      <td className="whitespace-nowrap px-6 py-4">2.60</td>
                      <td className="whitespace-nowrap px-6 py-4">2.50</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>


                    <tr className="">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center gap-2"><AirtelLogo /> <span> Airtel</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.80</td>
                      <td className="whitespace-nowrap px-6 py-4">2.70</td>
                      <td className="whitespace-nowrap px-6 py-4">2.60</td>
                      <td className="whitespace-nowrap px-6 py-4">2.50</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>


                    <tr className="bg-[white]">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center gap-2"><GloLogoIcon /> <span> Glo</span> </td>
                      <td className="whitespace-nowrap px-6 py-4">2.80</td>
                      <td className="whitespace-nowrap px-6 py-4">2.70</td>
                      <td className="whitespace-nowrap px-6 py-4">2.60</td>
                      <td className="whitespace-nowrap px-6 py-4">2.50</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>

                    <tr className="border-b border-neutral-200 dark:border-white/10">
                      <td className="whitespace-nowrap px-6 lg:px-14  py-4 font-medium flex items-center"><NineMobile /> <span>9Mobile</span></td>
                      <td className="whitespace-nowrap px-6 py-4">2.80</td>
                      <td className="whitespace-nowrap px-6 py-4">2.70</td>
                      <td className="whitespace-nowrap px-6 py-4">2.60</td>
                      <td className="whitespace-nowrap px-6 py-4">2.50</td>
                      <td className="whitespace-nowrap px-6 py-4">Negotiable</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>




    </div>
  );
};

export default ProductPricing;
