import React from 'react'
import Header from "../../../components/Header";
import Footer from '../../../components/Footer';
import VoiceTop from './VoiceTop';
import VoiceContent from './VoiceContent';


const VoicePage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
    <Header />
    <VoiceTop />
    <VoiceContent />
    <Footer/>
  </section>
  )
}

export default VoicePage;