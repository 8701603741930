import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import QueriesAndComplaints from './QueriesAndComplaint';
import Faq from './Faq';
 
 

const FaqPage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
    <Header />
     <Faq />
    <QueriesAndComplaints />
    <Footer />
  </section>
  )
}

export default FaqPage;