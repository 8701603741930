import { BrowserRouter, Routes, Route,} from "react-router-dom";
import Home from "../pages/homePage";
import AboutPage from "../pages/aboutPage";
import ErrorPage from "../pages/Error";
import "../index.css";
import FaqPage from "../pages/faqPage";
import BulkSMSPage from "../pages/productPage/BulkSMSPage";
import UsdPage from "../pages/productPage/USDPage";
import AirtimePage from "../pages/productPage/AirtimePage";
import VoicePage from "../pages/productPage/VoicePage";
import Webinar from "../pages/eventPage/webinar";

function MainRouter() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/bulksms" element={<BulkSMSPage />} />
          <Route path="/usd" element={<UsdPage />} />
          <Route path="/airtime" element={<AirtimePage />} />
          <Route path="/voice" element={<VoicePage />} />
          <Route path="/webinar" element={<Webinar />} />

          <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRouter;
