import React from "react";
import bgImg from "../../../assets/images/laptop.png";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";

const OurMission = () => {
  return (
    <div
      className="w-full  flex flex-col items-center justify-center px-4 sm:px-10 xl:px-28 py-28 text-white"
      style={{
        backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImg})`,  
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }} 
      >


      <div className="w-full lg:w-4/5 flex flex-col gap-10 lg:flex-row items-center lg:items-start justify-center">
        <div className="w-full">
          <SectionHeading title={"Our Mission"} />
        </div>


        <div className="w-full">
          <p className=" lg:max-w-6xl text-center lg:text-left">
            We Believe In Hard Work And Dedication It is very important to
            understand the dynamics of the tech industry in Africa. We can see
            the paradigm shift in the financial sector and how tech start ups
            are driving financial inclusion. The agriculture or educational
            sectors are not left out either. Piggybacking off tech, these
            sectors are galloping at impressive speeds and growing
            exponentially, creating jobs and expanding economies of different
            African countries. Our mission is to contribute to this impressive
            growth and support this new technology movement. Sleengshort has
            joined the fray of companies empowering the tech eco system in
            Africa by providing easy to use communication solutions to these
            champions. We cannot sit idly by and watch from the sidelines as
            these changes take place. We want to be part of this great African
            story and that is what propels us.
          </p>
        </div>

      </div>
    </div>
  );
};

export default OurMission;
